import * as React from 'react'
  /* @jsx mdx */
import { mdx } from '@mdx-js/react';
/* @jsxRuntime classic */

/* @jsx mdx */

import DefaultLayout from "D:/Clients/StephaneCatoire/Gastby/scatoire-perso/src/components/blog-post-layout.jsx";
import { graphql } from "gatsby";
export const query = graphql`
  query ($language: String!) {
    locales: allLocale(filter: { language: { eq: $language } }) {
      edges {
        node {
          ns
          data
          language
        }
      }
    }
  }
`;
export const _frontmatter = {};
const layoutProps = {
  query,
  _frontmatter
};
const MDXLayout = DefaultLayout;
export default function MDXContent({
  components,
  ...props
}) {
  return <MDXLayout {...layoutProps} {...props} components={components} mdxType="MDXLayout">


    <p>{`QRCodes, and especially since the COVID Outbreak, are now part of everyday's life. Created in 1994 by Denso-Wave, they have been released to the public in 1999 so that everyone can use them. There is a lot of ways to generate them, some cheaper than others, but I will teach you a method to create them in your Excel packages for free.`}</p>
    <h1>{`Use cases`}</h1>
    <p>{`You can use the library to generate QRCode, but EAN-13 and other codbars if you wish. This might require to download a font included in the package, but will not be covered here. You can embed a url in the data of the QRCode, and use it as a way to authenticate the data contained in your excel package, by providing a link to your ERP dashboard giving you an aggregated view of what is more detailed in your excel file, or you can use it to mass generate QRCode , the choice is yours.`}</p>
    <h1>{`How to insert a QR code in excel`}</h1>
    <ol>
      <li parentName="ol">{`First of all, you will need to visit this link: `}<a parentName="li" {...{
          "href": "https://github.com/JonasHeidelberg/barcode-vba-macro-only"
        }}>{`https://github.com/JonasHeidelberg/barcode-vba-macro-only`}</a>{`.`}</li>
      <li parentName="ol">{`As I suspect you, my dear reader, are not a seasoned developer knowing the intricacies of Github, let's just download the zip file
`}<img parentName="li" {...{
          "src": "./github.png",
          "alt": null
        }}></img></li>
      <li parentName="ol">{`In the zipfile, you will find the file barcody.bas. This is a visual basic file that you can import in the macro editor. `}</li>
      <li parentName="ol"><span parentName="li" {...{
          "className": "gatsby-resp-image-wrapper",
          "style": {
            "position": "relative",
            "display": "block",
            "marginLeft": "auto",
            "marginRight": "auto",
            "maxWidth": "549px"
          }
        }}>{`
      `}<a parentName="span" {...{
            "className": "gatsby-resp-image-link",
            "href": "/static/e23aefce99ac2158c9d8f9271faec95c/928ea/Importvba.png",
            "style": {
              "display": "block"
            },
            "target": "_blank",
            "rel": "noopener"
          }}>{`
    `}<span parentName="a" {...{
              "className": "gatsby-resp-image-background-image",
              "style": {
                "paddingBottom": "97.54601226993866%",
                "position": "relative",
                "bottom": "0",
                "left": "0",
                "backgroundImage": "url('data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAABQAAAAUCAIAAAAC64paAAAACXBIWXMAAA7DAAAOwwHHb6hkAAADX0lEQVQ4y12T23LbNhRF9f+f0Q9Ik06mfUpTO7acOr5IsiVRvIgACIAgQBIgiAsvEtWx3BmlXQ/nbc0+Z2af2WL1mlNGMEYQUkLynGUQZRBxznGGEQAEY5JhwUWGMpazDKG2badpGsdxtnh9SuKQ5QymKWcM7BME07JgIAVcCIwpJmwXhBDAnGIIICtElhEpa4LJ7NPtl6/z+e2P9HENA0BfIvQaZbs0J4xhRvagWG/jJNoFu3gfL8uCLsMckaIUnHM++/Bl8fFq9cvnb7/Pg6sV/vqM/lpmV8vs/uH5ZfXy+PiQJBEXRcGLvGDFm/I2SlEKIWZBBDZBsFgvWMHfbqaUEIwxXq5WwTYoreBGKNdIK/dVIq1sfCOdMp2xvZtRxh6f5tffru++39/ezOd3d3GSQIQIJUkck4ZABaGCaQ244YUpiCag3mcNZi2bHQ6H4TAslg81IxgBqfTpTNd1SZzgBu9EEJbhlgdIIVCDDd+m9T4qIyDTWdf1VYWv725+vX7ZQTYO/Xg4HI9Ha22w3RaaVa6yg1NecsNNb3Sna1frTvvRz8ZxPE0TwOzDA12lUtWlc34cx67rtNa1lmEecsPzlpW2wg3mRggjck2RhGf5zNC5RikppXPOe3+cptPp1PfDNtswm0MFWZsjCeMqIQ3NVJZUyUUu6jJIdrzgWUZwhinNh3FsrY0RyOuaiBJXOTeFsKLpmrbXTdf8K7/X7TgOFaM8x4IRTrOm4s7Z9SuMQrDdJBBgZy2CME1ThNB+/1Oysv0mt9fP8Y8dCQuzYyaVhxWswwjnOYEQlGUpZQ0hSpIEQhBF0UVmTfdnqD7ebH77O/70Pf7jCd3j/ipUyxWAAGw3W86FKIowDMMo3O12m/XmP2uPQ88JUoIXJFMlP51O3nfbbZYhFIXhuZosTdNknyRJEsc/JevW5EVJMKWUNbptjR0PR2M9IYU1RkqltfW+M8Y654wxSqmLnNdmgyqCEKXsfZdpmpzvAIBClFK6qjJVZYbxcDy+tWiapovctD6j5Wa9hhBZa/UZpRRCuKpLrfU4Dt77vusPh4Pzg++GizxNx77z7kzf9Uqp9fqVYIFASSmXtZSV1I22xvZdrxuvG3+R/8f7Y/jel22tvK5dVTupfFO7ujRCurpo838AuJBHMZkpZN0AAAAASUVORK5CYII=')",
                "backgroundSize": "cover",
                "display": "block"
              }
            }}></span>{`
  `}<img parentName="a" {...{
              "className": "gatsby-resp-image-image",
              "alt": "Importvba",
              "title": "Importvba",
              "src": "/static/e23aefce99ac2158c9d8f9271faec95c/928ea/Importvba.png",
              "srcSet": ["/static/e23aefce99ac2158c9d8f9271faec95c/222b7/Importvba.png 163w", "/static/e23aefce99ac2158c9d8f9271faec95c/ff46a/Importvba.png 325w", "/static/e23aefce99ac2158c9d8f9271faec95c/928ea/Importvba.png 549w"],
              "sizes": "(max-width: 549px) 100vw, 549px",
              "style": {
                "width": "100%",
                "height": "100%",
                "margin": "0",
                "verticalAlign": "middle",
                "position": "absolute",
                "top": "0",
                "left": "0"
              },
              "loading": "lazy",
              "decoding": "async"
            }}></img>{`
  `}</a>{`
    `}</span></li>
      <li parentName="ol">{`Now in your Excel file you can use a formula like `}</li>
    </ol>
    <pre><code parentName="pre" {...{}}>{`=EncodeBarcode(CELL("SHEET"),CELL("ADDRESS"),A2,51,1,0,2)
`}</code></pre>
    <ol>
      <li parentName="ol">{`The first argument in the function is the worksheet number`}</li>
      <li parentName="ol">{`The second is a reference for the cell address`}</li>
      <li parentName="ol">{`The third is the cell containing the data you want to be encoded, in our case the address to be used in our ERP system`}</li>
      <li parentName="ol">{`the fourth 51 is the option for QR Code. Other options are 1=EAN8/13/UPCA/UPCE, 2=two of five interleaved, 3=Code39, 50=Data Matrix, 51=QRCode`}</li>
      <li parentName="ol">{`The fifth 1 is for graphical mode. The barcode is drawn on a Shape object. `}</li>
      <li parentName="ol">{`the sixth 0 is the parameter for the particular barcode type. For QR_Code, 0=Low Error Correction, 1=Medium Error Correction, 2=Quartile error correction, 3=high error correction.`}</li>
      <li parentName="ol">{`2 only applies to 1D codes. `}</li>
      <li parentName="ol">{`A shape object will be generated containing the QR code.`}</li>
    </ol>
    <p>{`Et voilà ! You can now have a direct and independant way to embed QRCodes in your Excel packages. `}</p>
    <h1>{`Last remarks`}</h1>
    <p>{`As mentioned earlier, this might not be the most performant method to have QRCode in your excel package, but it is a full vba macro and does not require any link to any external library. If you wanted to have a more robust method to mass generate QR codes, I would use a pyhton script using the QRCode library. If you want me to explain this method, please leave a request in the comments.`}</p>
    {
      /* Footer to allow translation to be copied on each post
          Boring but wainting for a better solution */
    }



    </MDXLayout>;
}
;
MDXContent.isMDXComponent = true;
      