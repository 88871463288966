// src/components/blog-post-layout.js
import React from "react";
import Layout from "./layout";

import {
  useTranslation,
  useI18next,
} from "gatsby-plugin-react-i18next";

export default function BlogPostLayout({ children, pageContext }) {
  const {t} = useTranslation()
  const {language} = useI18next()
  const { title, date } = pageContext.frontmatter;
  const localizedDate =new Date(date).toLocaleDateString(language)

  return (
    <Layout>
      <header>
        <h1>{title}</h1>
        <em>{t("BlogPage.PostedOn",{localizedDate})}</em>
      </header>
      {children}
    </Layout>
  );
}